/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
const React = require("react")

// Closes the nav bar when the client route changes on screens with small viewports
exports.onRouteUpdate = () => {
  document.documentElement.classList.remove("nav-open")
}